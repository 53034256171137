import * as React from "react";
function SvgIcTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.856 9.864v7.704c0 .128-.04.232-.12.312a.422.422 0 01-.312.12H8.56a.422.422 0 01-.312-.12.422.422 0 01-.12-.312V9.864c0-.128.04-.232.12-.312.08-.08.184-.12.312-.12h.864c.128 0 .232.04.312.12.08.08.12.184.12.312zm3.432 0v7.704c0 .128-.04.232-.12.312a.422.422 0 01-.312.12h-.864a.422.422 0 01-.312-.12.422.422 0 01-.12-.312V9.864c0-.128.04-.232.12-.312.08-.08.184-.12.312-.12h.864c.128 0 .232.04.312.12.08.08.12.184.12.312zm3.408 0v7.704c0 .128-.04.232-.12.312a.393.393 0 01-.288.12h-.864a.422.422 0 01-.312-.12.422.422 0 01-.12-.312V9.864c0-.128.04-.232.12-.312.08-.08.184-.12.312-.12h.864c.112 0 .208.04.288.12.08.08.12.184.12.312zm1.728 9.696V6.864h-12V19.56c0 .192.032.368.096.528.064.16.128.28.192.36.064.08.112.12.144.12h11.136c.032 0 .08-.04.144-.12.064-.08.128-.2.192-.36a1.41 1.41 0 00.096-.528zm-9-14.424h6l-.648-1.56a.372.372 0 00-.216-.144h-4.248a.38.38 0 00-.24.144l-.648 1.56zm12.432.432v.864c0 .128-.04.232-.12.312a.422.422 0 01-.312.12h-1.296V19.56c0 .736-.208 1.376-.624 1.92-.416.544-.92.816-1.512.816H6.856c-.592 0-1.096-.264-1.512-.792-.416-.528-.632-1.16-.648-1.896V6.864H3.424a.422.422 0 01-.312-.12.422.422 0 01-.12-.312v-.864c0-.128.04-.232.12-.312.08-.08.184-.12.312-.12h4.128l.936-2.232c.144-.336.392-.616.744-.84.352-.224.704-.344 1.056-.36h4.272c.352 0 .704.12 1.056.36.352.24.592.52.72.84l.936 2.232h4.152c.128 0 .232.04.312.12.08.08.12.184.12.312z"
      />
    </svg>
  );
}
export default SvgIcTrash;
