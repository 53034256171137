import * as React from "react";
function SvgIcApp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M5.89 15.71v.806a.405.405 0 01-.115.3.405.405 0 01-.3.115H4.67a.459.459 0 01-.3-.116.316.316 0 01-.114-.3v-.806c0-.122.038-.222.115-.3a.405.405 0 01.3-.114h.806c.123 0 .223.038.3.115a.405.405 0 01.115.3zm0-3.295v.83a.376.376 0 01-.115.276.405.405 0 01-.3.115H4.67a.459.459 0 01-.3-.115c-.091-.077-.13-.17-.114-.277v-.83c0-.107.038-.207.115-.299a.316.316 0 01.3-.115h.806c.123 0 .223.038.3.115a.405.405 0 01.115.3zm0-3.295v.83c0 .107-.038.2-.115.276a.497.497 0 01-.3.138H4.67a.418.418 0 01-.3-.138c-.091-.092-.13-.184-.114-.277V9.12a.46.46 0 01.115-.3.316.316 0 01.3-.115h.806a.41.41 0 01.3.115.405.405 0 01.115.3zm14.815 6.59v.806a.405.405 0 01-.115.3.405.405 0 01-.3.115H7.965a.405.405 0 01-.3-.116.405.405 0 01-.115-.3v-.806a.41.41 0 01.116-.3.405.405 0 01.3-.114H20.29c.122 0 .222.038.3.115a.405.405 0 01.114.3zm0-3.295v.83a.376.376 0 01-.115.276.405.405 0 01-.3.115H7.965a.405.405 0 01-.3-.115.377.377 0 01-.115-.277v-.83c0-.107.039-.207.116-.299a.316.316 0 01.3-.115H20.29c.122 0 .222.038.3.115a.405.405 0 01.114.3zm0-3.295v.83c0 .107-.038.2-.115.276a.497.497 0 01-.3.138H7.965a.372.372 0 01-.3-.138.433.433 0 01-.115-.277V9.12a.46.46 0 01.116-.3.316.316 0 01.3-.115H20.29c.122 0 .222.039.3.115a.405.405 0 01.114.3zm1.66 9.055V7.485a.372.372 0 00-.14-.3.433.433 0 00-.276-.116H3.011c-.108 0-.2.039-.277.116a.497.497 0 00-.138.3v10.69c0 .107.046.2.138.276a.587.587 0 00.277.138h18.938a.35.35 0 00.277-.138.587.587 0 00.138-.276zM24 4.189v13.986c0 .568-.2 1.052-.599 1.451-.4.4-.883.6-1.452.6H3.011c-.553 0-1.037-.2-1.452-.6a1.866 1.866 0 01-.599-1.451V4.189c0-.568.2-1.052.599-1.451.4-.4.883-.607 1.452-.622h18.938a1.94 1.94 0 011.452.622c.4.415.599.898.599 1.451z"
        />
      </g>
    </svg>
  );
}
export default SvgIcApp;
