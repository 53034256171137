import * as React from "react";

function SvgIcCategories(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <g transform="translate(1.000000, 1.000000)" fill="currentColor">
          <path d="M11.4141824,0 L21.1209635,9.70575 C21.6838356,10.268394 22.0000729,11.03164 22.0000729,11.8275 C22.0000729,12.62336 21.6838356,13.386606 21.1211068,13.9491068 L13.9494999,21.1207134 C13.3869167,21.6839227 12.623617,22.0005731 11.8277072,22.0005731 C11.0318964,22.0005731 10.2687047,21.6847485 9.70581811,21.1220317 L0,11.4141513 L0,0 L11.4141824,0 Z M10.585,2 L2,2 L2,10.585 L11.1200001,19.7077866 C11.2762644,19.8640037 11.479059,19.9631829 11.6960602,19.9919033 L11.8277072,20.0005731 C12.0926114,20.0005731 12.3469212,19.8950742 12.5348932,19.7068932 L19.7070365,12.53475 C19.8946605,12.347202 20.0000729,12.0927867 20.0000729,11.8275 C20.0000729,11.5622133 19.8946605,11.307798 19.7069308,11.1201443 L10.585,2 Z M7,4 C8.65685425,4 10,5.34314575 10,7 C10,8.65685425 8.65685425,10 7,10 C5.34314575,10 4,8.65685425 4,7 C4,5.34314575 5.34314575,4 7,4 Z M7,6 C6.44771525,6 6,6.44771525 6,7 C6,7.55228475 6.44771525,8 7,8 C7.55228475,8 8,7.55228475 8,7 C8,6.44771525 7.55228475,6 7,6 Z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIcCategories;
