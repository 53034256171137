import * as React from "react";
function SvgIcChartScatterplot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M4.997 19C5.55 19.001 6 19.45 6 20.003a.994.994 0 01-.997.997A1.006 1.006 0 014 19.997.994.994 0 014.997 19zm3.992-8A4.025 4.025 0 0113 15.01 3.975 3.975 0 019.01 19 4.025 4.025 0 015 14.99 3.975 3.975 0 018.99 11zm-6.493 4A1.51 1.51 0 014 16.504 1.49 1.49 0 012.504 18 1.51 1.51 0 011 16.496 1.49 1.49 0 012.496 15zm13.501-1c.553.001 1.002.45 1.003 1.003a.994.994 0 01-.997.997A1.006 1.006 0 0115 14.997a.994.994 0 01.997-.997zm4.998-2c1.104.003 2.002.9 2.005 2.005A1.987 1.987 0 0121.005 16 2.013 2.013 0 0119 13.995 1.987 1.987 0 0120.995 12zm-5.003-6A3.02 3.02 0 0119 9.008 2.981 2.981 0 0116.008 12 3.02 3.02 0 0113 8.992 2.981 2.981 0 0115.992 6zM9.997 7C10.55 7.001 11 7.45 11 8.003a.994.994 0 01-.997.997A1.006 1.006 0 019 7.997.994.994 0 019.997 7zm11-1C21.55 6.001 22 6.45 22 7.003a.994.994 0 01-.997.997A1.006 1.006 0 0120 6.997.994.994 0 0120.997 6zm-4-3C17.55 3.001 18 3.45 18 4.003a.994.994 0 01-.997.997A1.006 1.006 0 0116 3.997.994.994 0 0116.997 3z"
        />
      </g>
    </svg>
  );
}
export default SvgIcChartScatterplot;
