import * as React from "react";
function SvgIcEye(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M22.296 12.864c-1.36-2.112-3.064-3.688-5.112-4.728.544.928.816 1.928.816 3 0 1.664-.584 3.08-1.752 4.248-1.168 1.168-2.584 1.752-4.248 1.752-1.664 0-3.08-.584-4.248-1.752C6.584 14.216 6 12.8 6 11.136c0-1.072.272-2.072.816-3-2.048 1.04-3.752 2.616-5.112 4.728 1.2 1.824 2.696 3.28 4.488 4.368A10.992 10.992 0 0012 18.864c2.08 0 4.016-.544 5.808-1.632 1.792-1.088 3.288-2.544 4.488-4.368zm-9.648-5.16a.623.623 0 00-.192-.456A.547.547 0 0012 7.08c-1.12 0-2.08.4-2.88 1.2-.8.8-1.2 1.752-1.2 2.856 0 .192.064.344.192.456.128.112.28.176.456.192a.522.522 0 00.456-.192.679.679 0 00.192-.456c0-.768.272-1.424.816-1.968A2.682 2.682 0 0112 8.352a.623.623 0 00.456-.192.623.623 0 00.192-.456zM24 12.864c0 .304-.088.608-.264.912-1.248 2.064-2.928 3.712-5.04 4.944-2.112 1.232-4.344 1.848-6.696 1.848-2.352 0-4.584-.616-6.696-1.848-2.112-1.232-3.792-2.88-5.04-4.944A1.807 1.807 0 010 12.864c0-.304.088-.616.264-.936 1.248-2.048 2.928-3.688 5.04-4.92C7.416 5.776 9.648 5.152 12 5.136c2.352-.016 4.584.608 6.696 1.872 2.112 1.264 3.792 2.904 5.04 4.92.176.32.264.632.264.936z"
        />
      </g>
    </svg>
  );
}
export default SvgIcEye;
