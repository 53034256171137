import * as React from "react";
function SvgIcPdf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M21.174 4.674l-3.349-3.348C17.097.596 15.656 0 14.625 0H3.375C2.344 0 1.5.844 1.5 2.625v20.25c0 .281.844 1.125 1.875 1.125h17.25a1.88 1.88 0 001.875-1.875V7.875c0-1.031-.597-2.472-1.326-3.201zM16.5 2.169c.102.071.192.144.265.217l3.348 3.348c.073.074.146.163.217.265h-3.831V2.168l.001.001zM3 1.875a.38.38 0 01.375-.375h11.25c.113 0 .24.014.375.04V7.5h5.96c.026.135.04.262.04.375V9H3V1.875zM20.625 22.5H3.375A.38.38 0 013 22.125V19.5h18v2.625a.38.38 0 01-.375.375zm-.072-8.818v1.342h-2.609v2.739h-1.591v-7.058h4.655v1.343h-3.064v1.635h2.609v-.001zm-5.847.52c0 .607-.081 1.134-.244 1.582a2.98 2.98 0 01-.682 1.109c-.293.292-.64.511-1.045.655a3.98 3.98 0 01-1.342.217H9.39v-7.059h1.916c.512 0 .978.068 1.397.205.419.137.776.348 1.072.634.296.285.525.648.688 1.088.163.44.244.963.244 1.57l-.001-.001zm-2.149-1.738a1.386 1.386 0 00-.585-.363 2.458 2.458 0 00-.752-.108h-.227v4.482h.227c.274 0 .525-.04.752-.119.227-.079.422-.208.585-.385.163-.177.289-.409.379-.698.09-.289.135-.646.135-1.072 0-.418-.045-.77-.135-1.055a1.724 1.724 0 00-.379-.682zm-4.227.514c0 .413-.075.769-.222 1.072a2.067 2.067 0 01-.601.747 2.545 2.545 0 01-.877.433 3.875 3.875 0 01-1.061.141h-.986v2.392H2.992v-7.058h2.577c.382 0 .742.038 1.077.114.335.076.628.202.877.379s.445.412.59.704c.145.293.217.653.217 1.078v-.002zm-2.858-1.007h-.889v2.133l.889.001c.859 0 1.288-.375 1.288-1.127 0-.368-.108-.628-.325-.779-.217-.151-.538-.227-.964-.227l.001-.001z"
        />
      </g>
    </svg>
  );
}
export default SvgIcPdf;
