import * as React from "react";

function SvgIcRefresh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <rect x="0" y="0" width="24" height="24"></rect>
          <path
            d="M14.944,18.0072727 L15.4421818,19.3738182 C13.6778182,20.0167273 13.3745455,20.0749091 11.4727273,20.1214545 L11.4370909,18.6676364 C13.1796364,18.6247273 13.336,18.5927273 14.944,18.0072727 Z M6.82254545,16.1614545 C8.04363636,17.4094545 8.19127273,17.5156364 9.74763636,18.2923636 L9.09672727,19.5934545 C7.38254545,18.7374545 7.11927273,18.5425455 5.78327273,17.1789091 Z M18.1367273,14.4065455 L19.4450909,15.0414545 C18.664,16.6516364 18.5418182,16.8305455 17.3141818,18.1410909 L16.2530909,17.1469091 C17.3730909,15.9505455 17.4218182,15.8792727 18.1367273,14.4065455 Z M5.45454545,11.3789091 C5.45454545,13.0523636 5.472,13.1687273 5.97527273,14.7578182 L4.58909091,15.1970909 C4.03927273,13.4610909 4,13.2138182 4,11.3789091 L5.45454545,11.3789091 Z M4.96945455,4 L6.97672727,5.88218182 C8.39490909,4.73309091 10.1490909,4.10618182 12,4.10618182 C16.4109091,4.10618182 20,7.69527273 20,12.1061818 C20,12.3323636 19.9847273,12.5549091 19.9658182,12.7767273 L18.5229091,12.5512727 C18.5330909,12.4036364 18.5454545,12.2567273 18.5454545,12.1061818 C18.5454545,8.49672727 15.6094545,5.56072727 12,5.56072727 C10.5534545,5.56072727 9.18327273,6.03345455 8.05090909,6.89381818 L10.0298182,8.74327273 L4.49963636,9.51563636 L4.96945455,4 Z"
            fill="#454545"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SvgIcRefresh;
